
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import data from './js/data.js';
export default {
  data() {
    return { loggedIn: false };
  },
  mounted() {
    this.$f7ready(() => {
      var self = this;
      const config = {
        apiKey: 'AIzaSyC4TTdnqxZLOVz2GZETNdtM9niT1sBqZ9I',
        authDomain: 'cssi-13cc7.firebaseapp.com',
        databaseURL: 'https://cssi-13cc7.firebaseio.com',
        projectId: 'cssi-13cc7',
        storageBucket: 'cssi-13cc7.appspot.com',
        messagingSenderId: '279321198693',
        appId: '1:279321198693:web:f96153c320d6e92525068c'
      };
      const firebaseApp = firebase.initializeApp(config);
      self.$setState({ firebase: firebaseApp });
      firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
          self.$setState({
            loggedIn: true,
            data: data
          }, () => {
            var panelRight = self.$app.panel.create({
              el: '.panel-right',
              visibleBreakpoint: 1024
            });
            panelRight.resizableWidth = localStorage.getItem('widthPanelRight');
            panelRight.disableVisibleBreakpoint();
            var panelLeft = self.$app.panel.create({
              el: '.panel-left',
              visibleBreakpoint: 768
            });
            var viewMainParams = {
              url: '/',
              main: true,
              on: {
                pageBeforeIn: function (page) {
                  if (page.name == 'home') {
                    panelRight.disableVisibleBreakpoint();
                  }
                }
              }
            };
            var viewLeftParams = { url: '/panel-left-home/' };
            var viewRightParams = {
              url: '/panel-right-home/',
              animate: false,
              iosSwipeBack: false
            };
            if (self.$app.device.desktop) {
              viewMainParams.pushState = true;
              viewMainParams.animate = false;
              viewLeftParams.animate = false;
            }
            var viewMain = self.$app.views.create('.view-main', viewMainParams);
            var viewLeft = self.$app.views.create('.panel-left .view.view-left', viewLeftParams);
            var viewRight = self.$app.views.create('.panel-right .view.view-right', viewRightParams);
            self.$('.lds').addClass('modal-out');
            self.$app.utils.nextTick(() => {
              self.$('.lds').remove();
            }, 300);
          });
        } else {
          self.$setState({ loggedIn: false }, () => {
            self.$app.views.create('.login-screen .view', { url: '/auth/' });
            self.$('.lds').addClass('modal-out');
            self.$app.utils.nextTick(() => {
              self.$('.lds').remove();
            }, 300);
          });
        }
      });
      self.$app.on('serviceWorkerRegisterSuccess', function (registration) {
        registration.addEventListener('updatefound', () => {
          var newWorker = registration.installing;
          newWorker.addEventListener('statechange', () => {
            switch (newWorker.state) {
            case 'installed':
              if (navigator.serviceWorker.controller) {
                console.log('There is a new service worker available, show the notification');
                self.$app.toast.create({
                  text: 'Une nouvelle version est disponnible !',
                  closeButton: true,
                  closeButtonText: 'Recharger',
                  closeButtonColor: 'white',
                  on: {
                    close: function () {
                      location.reload();
                    }
                  }
                }).open();
              }
              break;
            }
          });
        });
      });
    });
  },
  methods: {
    ValidateEmail(mail, func) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        func(true);
      } else {
        func(false);
      }
    },
    firebaseAuth(email, password, func) {
      func(null);
      firebase.auth().signInWithEmailAndPassword(email, password).catch(function (error) {
        func(error.code);
      });
    }
  },
  id: 'b1d0aee6d8',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div id="app">';
      r += Template7Helpers.if.call(ctx_1, ctx_1.loggedIn, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="panel panel-left panel-cover"><div class="view view-left"></div></div><div class="panel panel-right panel-cover panel-resizable"><div class="view view-right"></div></div><div class="view view-main safe-areas"></div>';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="login-screen modal-in"><div class="view"></div></div>';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>';
      return r;
    }(this);
  },
  style: `.panel.panel-right {
  min-width: 250px;
  max-width: 50%;
}
.panel-left.panel-in-breakpoint ~ .view .navbar .panel-open[data-panel="left"] {
  display: none;
}
.panel-left.panel-in-breakpoint:before,
.panel-right.panel-in-breakpoint:before {
  position: absolute;
  top: 0;
  height: 100%;
  width: 1px;
  background: rgba(0,0,0,0.1);
  content: '';
  z-index: 6000;
}
.panel-left.panel-in-breakpoint:before {
  right: 0;
}
.panel-right.panel-in-breakpoint:before {
  left: 0;
}`,
  styleScoped: false
};
    