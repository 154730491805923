
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data() {
    return {
      pageName: 'auth',
      connexion: false,
      email: false
    };
  },
  mounted() {
    var self = this;
    self.$f7ready(() => {
      self.$('.page[data-name="auth"] a.seConnecter').on('click', () => {
        self.$setState({ connexion: true }, () => {
          self.$('input[name="username"]').on('keyup keydown change', function (e) {
            self.$root.ValidateEmail(e.target.value, valid => {
              if (valid) {
                self.$setState({ email: true }, () => {
                  self.$('.page[data-name="auth"] a.connexion').off('click').on('click', e => {
                    var email = self.$('input[name="username"]').val(), password = self.$('input[name="password"]').val();
                    self.$root.firebaseAuth(email, password, function (err) {
                      self.$(e.target).text('Chargement...');
                      if (err) {
                        if (err == 'auth/invalid-email') {
                          self.$app.toast.create({
                            text: 'L\'adresse e-mail n\'est pas valide.',
                            closeButton: true,
                            closeButtonColor: 'white'
                          }).open();
                        } else if (err == 'auth/user-disabled') {
                          self.$app.toast.create({
                            text: 'L\'utilisateur correspondant à l\'e-mail donné a été désactivé.',
                            closeButton: true,
                            closeButtonColor: 'white'
                          }).open();
                        } else if (err == 'auth/user-not-found') {
                          self.$app.toast.create({
                            text: 'Aucun utilisateur correspondant à l\'e-mail donné.',
                            closeButton: true,
                            closeButtonColor: 'white'
                          }).open();
                        } else if (err == 'auth/wrong-password') {
                          self.$app.toast.create({
                            text: 'Le mot de passe n\'est pas valide pour l\'e-mail donné.',
                            closeButton: true,
                            closeButtonColor: 'white'
                          }).open();
                        }
                        self.$(e.target).text('Connexion');
                      }
                    });
                  });
                });
              } else {
                self.$setState({ email: false });
              }
            });
          });
        });
      });
    });
  },
  id: '777eeb09ae',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div data-name="';
      r += c(ctx_1.pageName, ctx_1);
      r += '" class="page"><div class="page-content login-screen-content"><div class="login-screen-title">';
      r += c(ctx_1.$app.name, ctx_1);
      r += '</div><form>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.connexion, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="list"><ul><li class="item-content item-input"><div class="item-inner"><div class="item-input-wrap"><input type="email" name="username" placeholder="Email"/><span class="input-clear-button"></span></div></div></li>';
          r += Template7Helpers.if.call(ctx_2, ctx_2.email, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li class="item-content item-input"><div class="item-inner"><div class="item-input-wrap"><input type="password" name="password" placeholder="Mot de passe"/><span class="input-clear-button"></span></div></div></li></ul></div><div class="list"><ul><li><a href="#" class="item-link list-button connexion">Connexion</a></li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</ul></div>';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="list"><ul><li><a href="#" class="item-link list-button seConnecter">Se connecter</a></li></ul></div>';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</form></div></div>';
      return r;
    }(this);
  },
  styleScoped: false
};
    