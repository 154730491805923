
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data() {
    return {
      pageName: 'info',
      title: 'Commentaire',
      subtitle: ''
    };
  },
  mounted() {
    this.$f7ready(() => {
      var self = this, paramBat = self.$route.params.bat, paramType = self.$route.params.type, paramChapitre = self.$route.params.chapitre, paramSousChapitre = self.$route.params.sous_chapitre, paramArticle = self.$route.params.article, paramCommentaire = self.$route.params.commentaire, bat = self.$root.data[paramBat], type = bat[paramType], panelRight = self.$app.panel.get('.panel-right');
      if (paramSousChapitre) {
        var data = type.chapitres[paramChapitre].articles[paramSousChapitre].sous_chapitres[paramArticle];
      } else {
        var data = type.chapitres[paramChapitre].articles[paramArticle];
      }
      self.$setState({ commentaire: data.commentaires[paramCommentaire].texte }, () => {
        self.$el.find('.page[data-name="info"] a.panel-close').on('click', function () {
          panelRight.disableVisibleBreakpoint();
        });
        panelRight.on('close', function () {
          localStorage.setItem('widthPanelRight', panelRight.resizableWidth);
        });
      });
    });
  },
  methods: {
    onClick: function () {
      var self = this, currentImgClick = self.$(event.target).attr('src'), photoBrowserIndex = 0, linkImg = [];
      self.$el.find('img').each(function (index) {
        var srcImg = self.$(this).attr('src');
        if (srcImg == currentImgClick) {
          photoBrowserIndex = index;
        }
        if (self.$(this).parent('figure').length) {
          linkImg.push({
            url: srcImg,
            caption: self.$(this).parent('figure').find('figcaption').text()
          });
        } else {
          linkImg.push({ url: srcImg });
        }
      });
      var photoBrowser = self.$app.photoBrowser.create({
        photos: linkImg,
        type: 'popup',
        popupCloseLinkText: 'Fermer',
        navbarOfText: 'sur',
        exposition: false,
        routableModals: false,
        swipeToClose: false,
        swiper: { zoom: { maxRatio: 2 } }
      });
      photoBrowser.open(photoBrowserIndex);
    }
  },
  id: '1a9695d6da',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div data-name="';
      r += c(ctx_1.pageName, ctx_1);
      r += '" class="page"><div class="navbar navbar-large"><div class="navbar-bg"></div><div class="navbar-inner"><div class="title sliding">';
      r += c(ctx_1.title, ctx_1);
      r += '\n';
      r += Template7Helpers.if.call(ctx_1, ctx_1.subtitle, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<span class="subtitle">';
          r += c(ctx_2.subtitle, ctx_2);
          r += '</span>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div><div class="right"><a href="#" data-panel=".panel-right" class="panel-close"><i class="icon f7-icons">multiply_circle</i></a></div><div class="title-large"><div class="title-large-text">';
      r += c(ctx_1.title, ctx_1);
      r += '</div></div></div></div><div class="page-content">';
      r += c(ctx_1.commentaire, ctx_1);
      r += '<div class="block block-strong no-hairlines no-margin-bottom"><p>Source : Guide illustré - Accessibilité des établissements recevant du public et installations ouvertes au public existants (2019) - Direction de l\'Habitat, de l\'Urbanisme et des Paysages</p></div></div></div>';
      return r;
    }(this);
  },
  style: `[data-f7-1a9695d6da] img {
  display: block;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}`,
  styleScoped: true
};
    