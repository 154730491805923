import $$ from 'dom7';
import Framework7 from 'framework7/framework7.esm.bundle.js';

// Import F7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.less';

// Import Routes
import routes from './routes.js';

// Import main app component
import App from '../app.f7.html';

var app = new Framework7({
  root: '#app',
  component: App,
  name: "Prev' HAND",
  theme: Framework7.device.desktop ? 'aurora' : 'auto',
  routes: routes,
  dialog: {
    buttonCancel: "Annuler",
    preloaderTitle: "Chargement...",
    progressTitle: "Chargement..."
  },
  methods: {
    groupBy: function(xs, key) {
      if (xs !== undefined) {
        return xs.reduce(function(rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
        console.log(xs);
      } else {
        return null;
      }
    },
  },
  serviceWorker: {
    path: '/service-worker.js',
  },
});
