
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data() {
    return { pageName: 'index-bat' };
  },
  mounted() {
    this.$f7ready(() => {
      var self = this, bat = self.$route.params.bat, type = self.$route.params.type, texte = self.$root.data[bat][type]['texte'], descriptif = self.$root.data[bat][type]['descriptif'], data = self.$root.data[bat][type]['chapitres'];
      self.$setState({
        title: bat.toUpperCase() + ' ' + type.charAt(0).toUpperCase() + type.substr(1),
        bat: bat,
        type: type,
        texte: texte,
        descriptif: descriptif,
        data: data
      });
    });
  },
  id: '1eb94c6dc6',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div data-name="';
      r += c(ctx_1.pageName, ctx_1);
      r += '" class="page"><div class="navbar navbar-large"><div class="navbar-bg"></div><div class="navbar-inner"><div class="left"><a href="#" class="link back"><i class="icon icon-back"></i><span class="if-not-md">Retour</span></a></div><div class="title sliding">';
      r += c(ctx_1.title, ctx_1);
      r += '\n';
      r += Template7Helpers.if.call(ctx_1, ctx_1.subtitle, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<span class="subtitle">';
          r += c(ctx_2.subtitle, ctx_2);
          r += '</span>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div><div class="title-large"><div class="title-large-text">';
      r += c(ctx_1.title, ctx_1);
      r += '</div></div></div></div><div class="page-content"><div class="block-title">';
      r += c(ctx_1.texte, ctx_1);
      r += '</div><div class="block">';
      r += c(ctx_1.descriptif, ctx_1);
      r += '</div>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.data, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n';
          r += Template7Helpers.if.call(ctx_2, ctx_2.titre_chapitre, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<div class="block-title">';
              r += c(ctx_3.titre_chapitre, ctx_3);
              r += '</div>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '<div class="list"><ul>';
          r += Template7Helpers.each.call(ctx_2, ctx_2.articles, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li><a href="/';
              r += c(ctx_1.bat, ctx_3);
              r += '/';
              r += c(ctx_1.type, ctx_3);
              r += '/';
              r += c(data_2 && data_2.index, ctx_3);
              r += '/';
              r += c(data_3 && data_3.index, ctx_3);
              r += '/" data-view=".view-main" class="item-link item-content panel-close"><div class="item-inner"> <div class="item-title">';
              r += c(ctx_3.article, ctx_3);
              r += '<div class="item-footer">';
              r += c(ctx_3.titre, ctx_3);
              r += '</div></div></div></a></li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</ul></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div>';
      return r;
    }(this);
  },
  styleScoped: true
};
    