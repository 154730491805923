
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data() {
    return {
      pageName: 'home',
      title: 'Prev\' HAND',
      subtitle: ''
    };
  },
  mounted() {
    this.$f7ready(() => {
      var self = this, firebase = self.$root.firebase, db = firebase.firestore();
    });
  },
  id: '47eaa36483',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div data-name="';
      r += c(ctx_1.pageName, ctx_1);
      r += '" class="page"><div class="navbar navbar-large"><div class="navbar-bg"></div><div class="navbar-inner"><div class="left"><a href="/user/" class="link icon-only"><i class="icon f7-icons if-not-md">person_alt_circle_fill</i><i class="icon material-icons if-md">person_alt_circle_fill</i></a></div><div class="title sliding">';
      r += c(ctx_1.title, ctx_1);
      r += '\n';
      r += Template7Helpers.if.call(ctx_1, ctx_1.subtitle, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<span class="subtitle">';
          r += c(ctx_2.subtitle, ctx_2);
          r += '</span>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div><div class="right"><a data-panel="left" class="link icon-only panel-open"><i class="icon material-icons">menu</i></a></div><div class="title-large"><div class="title-large-text">';
      r += c(ctx_1.title, ctx_1);
      r += '</div></div></div></div><div class="page-content"><div class="block"><p>Depuis la loi du 11 février 2005 pour l\u2019égalité des droits et des chances, la participation et la citoyenneté des personnes handicapées, les établissements recevant du public (ERP) doivent être accessibles à tous les types de handicap. Ils doivent permettre à tout le monde, sans distinction, de pouvoir y accéder, y circuler et recevoir les informations diffusées.</p><p>Avant de commencer, passons en revue  <a href="/avant-propos/">les objectifs et enjeux de l\'accessibilité dans les ERP</a> !</p></div></div></div>';
      return r;
    }(this);
  },
  styleScoped: true
};
    