
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data() {
    return {
      pageName: '404',
      title: 'Erreur 404',
      subtitle: ''
    };
  },
  id: 'ac08aeb7e6',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div data-name="';
      r += c(ctx_1.pageName, ctx_1);
      r += '" class="page"><div class="navbar"><div class="navbar-bg"></div><div class="navbar-inner"><div class="left"><a href="#" class="link back"><i class="icon icon-back"></i><span class="if-not-md">Retour</span></a></div><div class="title sliding">';
      r += c(ctx_1.title, ctx_1);
      r += '\n';
      r += Template7Helpers.if.call(ctx_1, ctx_1.subtitle, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<span class="subtitle">';
          r += c(ctx_2.subtitle, ctx_2);
          r += '</span>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div><div class="right"><a data-panel="left" class="link icon-only panel-open"><i class="icon material-icons">menu</i></a></div></div></div><div class="page-content"><div class="block"><p>Cette page n\'existe pas !...</p></div></div></div>';
      return r;
    }(this);
  },
  styleScoped: false
};
    