
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data() {
    return {
      pageName: 'detail',
      title: '',
      subtitle: ''
    };
  },
  mounted() {
    this.$f7ready(() => {
      var self = this, paramBat = self.$route.params.bat, paramType = self.$route.params.type, paramChapitre = self.$route.params.chapitre, paramSousChapitre = self.$route.params.sous_chapitre, paramArticle = self.$route.params.article, bat = self.$root.data[paramBat], type = bat[paramType], panelRight = self.$app.panel.get('.panel-right');
      if (paramSousChapitre) {
        var data = type.chapitres[paramChapitre].articles[paramSousChapitre].sous_chapitres[paramArticle];
      } else {
        var data = type.chapitres[paramChapitre].articles[paramArticle];
      }
      self.$setState({
        title: data.titre,
        subtitle: data.article + ' - ' + type.texte,
        data: data
      }, function () {
        panelRight.on('open', function () {
          panelRight.enableVisibleBreakpoint();
        });
      });
    });
  },
  id: '511e3b81aa',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div data-name="';
      r += c(ctx_1.pageName, ctx_1);
      r += '" class="page"><div class="navbar navbar-large"><div class="navbar-bg"></div><div class="navbar-inner"><div class="left"><a href="#" class="link back"><i class="icon icon-back"></i><span class="if-not-md">Retour</span></a></div><div class="title sliding">';
      r += c(ctx_1.title, ctx_1);
      r += '\n';
      r += Template7Helpers.if.call(ctx_1, ctx_1.subtitle, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<span class="subtitle">';
          r += c(ctx_2.subtitle, ctx_2);
          r += '</span>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div><div class="right"><a data-panel="left" class="link icon-only panel-open"><i class="icon material-icons">menu</i></a></div><div class="title-large"><div class="title-large-text">';
      r += c(ctx_1.title, ctx_1);
      r += '</div></div></div></div><div class="page-content">';
      r += Template7Helpers.if.call(ctx_1, ctx_1.data, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n';
          r += Template7Helpers.if.call(ctx_2, ctx_2.data.descriptif, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n';
              r += c(ctx_3.data.descriptif, ctx_3);
              r += '\n';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n';
          r += Template7Helpers.if.call(ctx_2, ctx_2.data.usages_attendus, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<div class="block-title block-title-medium">Usages attendus</div><div class="block block-strong">';
              r += c(ctx_3.data.usages_attendus, ctx_3);
              r += '</div>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n';
          r += Template7Helpers.if.call(ctx_2, ctx_2.data.caracteristiques_minimales, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<div class="block-title block-title-medium">Caractéristiques minimales</div><div class="block block-strong">';
              r += c(ctx_3.data.caracteristiques_minimales, ctx_3);
              r += '</div>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div>';
      return r;
    }(this);
  },
  styleScoped: true
};
    