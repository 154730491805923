import HomePage from '../pages/home.f7.html';
import PanelRightHomePage from '../pages/panel-right-home.f7.html';
import PanelLeftHomePage from '../pages/panel-left-home.f7.html';

import AvantPropos from '../pages/avant-propos.f7.html';

import IndexBat from '../pages/index-bat.f7.html';
import Article from '../pages/article.f7.html';

import DetailArticle from '../pages/detail-article.f7.html';

import UserPage from '../pages/user.f7.html';

import NotFoundPage from '../pages/404.f7.html';

import AuthPage from '../pages/auth.f7.html';

var routes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/panel-left-home/',
    component: PanelLeftHomePage,
  },
  {
    path: '/panel-right-home/',
    component: PanelRightHomePage,
  },
  {
    path: '/avant-propos/',
    component: AvantPropos,
  },
  {
    path: '/:bat/:type/',
    component: IndexBat,
  },
  {
    path: '/:bat/:type/:chapitre/:article/',
    component: Article,
  },
  {
    path: '/:bat/:type/:chapitre/:sous_chapitre/:article/',
    component: Article,
  },
  {
    path: '/:bat/:type/:chapitre/:article/info/:commentaire/',
    component: DetailArticle,
  },
  {
    path: '/:bat/:type/:chapitre/:sous_chapitre/:article/info/:commentaire/',
    component: DetailArticle,
  },
  {
    path: '/user/',
    component: UserPage,
  },
  {
    path: '/auth/',
    component: AuthPage,
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
